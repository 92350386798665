import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getCodingByID, getConditionBySlug } from '@medifind/interface';
import { toSlug, useRouter } from '@medifind/router';
import { toProperLowerCase } from '@medifind/utils';
import { setAdditionalRouteParams, useRouteStore } from '@medifind/zustand';

export const useConditionParams = (params) => {
  const router = useRouter();
  const { routeUUID } = useRouteStore();
  const { projectId, codingSlug } = { ...router.query, ...(params || {}) };

  const { data: conditionBySlug, isFetching: isConditionBySlugFetching } = useQuery({
    queryKey: ['conditionBySlug', codingSlug, projectId],
    queryFn: () => getConditionBySlug(codingSlug).catch(() => ({})),
    enabled: !!codingSlug && projectId == null,
    staleTime: Infinity,
  });

  /** *****************************************************
   * START CONDITION - Get data for condition name from by project id
   */

  const {
    data: coding,
    isFetching: isCodingFetching,
    isError,
  } = useQuery({
    queryKey: ['coding', projectId],
    queryFn: () => getCodingByID(projectId),
    enabled: projectId != null,
    staleTime: Infinity,
  });

  const isFetching = projectId ? isCodingFetching : isConditionBySlugFetching;
  useEffect(() => {
    if (!isFetching) {
      if (conditionBySlug) {
        setAdditionalRouteParams(
          {
            codingName: conditionBySlug.display,
            lowerCaseCodingName: toProperLowerCase(conditionBySlug.display),
            projectId: conditionBySlug.projectId,
            codingSlug,
            codingType: conditionBySlug.codingType,
          },
          routeUUID,
        );
      } else {
        if (coding === null || isError) {
          setAdditionalRouteParams({ codingNotFound: true }, routeUUID);
        } else {
          if (coding) {
            setAdditionalRouteParams(
              {
                projectId,
                codingName: coding.display,
                isRareCondition: coding.isRareCondition,
                lowerCaseCodingName: toProperLowerCase(coding.display),
                codingSlug: toSlug(coding.display),
                codingType: coding.codingType,
              },
              routeUUID,
            );
          }
          if (coding?.codingType === 'procedure') {
            return router.history.replace(router.location.pathname.replace('conditions', 'procedures'));
          }
        }
      }
    }
  }, [coding, conditionBySlug, routeUUID, codingSlug, isFetching, isError]);
  /** *****************************************************
   * END CONDITION
   */

  return null;
};
